.details-modal {
  max-height: 90vh;
  overflow-y: auto;
  /* border: none; */
}
.approve-button {
}
.approve-button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  background-color: #171717;
  padding: 1em 2em;
  border: none;
  border-radius: 0.6rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.screen-name-header {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins";
}

.approve-reject-button {
  font-family: poppins;
  font-size: 14px;
  /* background: royalblue; */
  color: white;
  min-width: 120px;
  min-height: 30px;
  text-align: center;
  /* padding: 0.7em 1em; */
  /* padding-left: 0.9em; */
  display: flex;
  justify-content: "center";
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  /* background-image: linear-gradient(to right, #23b2d5, #219bcc); */
  /* padding: 10px; */
}
.view-details-button {
  border: 1px solid #22aad2;
  padding: 2px 7px;
  border-radius: 6px;
  color: #22aad2;
  cursor: pointer;
}
.approve-reject-button span {
  display: block;
  margin-left: 3em;
  transition: all 0.3s ease-in-out;
}

.approve-reject-button:active {
  transform: scale(0.95);
}
/* 
.approve-button span:not(:nth-child(6)) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  background-color: green;
  border-radius: 50%;
  transition: 0.6s ease;
}

.approve-button span:nth-child(6) {
  position: relative;
}

.approve-button span:nth-child(1) {
  transform: translate(-3.3em, -4em);
}

.approve-button span:nth-child(2) {
  transform: translate(-6em, 1.3em);
}

.approve-button span:nth-child(3) {
  transform: translate(-0.2em, 1.8em);
}

.approve-button span:nth-child(4) {
  transform: translate(3.5em, 1.4em);
}

.approve-button span:nth-child(5) {
  transform: translate(3.5em, -3.8em);
}

.approve-button:hover span:not(:nth-child(6)) {
  transform: translate(-50%, -50%) scale(4);
  transition: 1.5s ease;
} */

.Documents-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  height: 35px;
  margin: 5px;
  border: none;
  padding: 0px 15px;
  border-radius: 5px;
  background-color: rgb(49, 49, 83);
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.folderContainer {
  width: 40px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.fileBack {
  z-index: 1;
  width: 80%;
  height: auto;
}
.filePage {
  width: 50%;
  height: auto;
  position: absolute;
  z-index: 2;
  transition: all 0.3s ease-out;
}
.fileFront {
  width: 85%;
  height: auto;
  position: absolute;
  z-index: 3;
  opacity: 0.95;
  transform-origin: bottom;
  transition: all 0.3s ease-out;
}
.text {
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.Documents-btn:hover .filePage {
  transform: translateY(-5px);
}
.Documents-btn:hover {
  background-color: rgb(58, 58, 94);
}
.Documents-btn:hover .fileFront {
  transform: rotateX(30deg);
}
