.approval-modal-buttons {
  margin: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.collapsiable-item {
  display: flex;
  /* justify-content: center; */
  gap: 10px;
  align-items: center;
  margin-left: 5px;
}
.collapsiable-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  margin: 5px;
  background-color: #eeeeee;
  border-radius: 10px;
  gap: 10px;
}
.collapsible-body-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  margin: 5px;
  background-color: #eeeeee;
  border-radius: 10px;
  /* gap: 10px; */
}
.collapsiable-item-content {
  color: #24bbd9;
}
.default-chip {
  color: #24bbd9;
  align-items: center;
  display: flex;
  margin-left: 5px;
}
collapsiable-item-header.dropArea {
  color: white !important;
  background: white !important;
  position: relative;
}
.collapsiable-item-title {
  font-size: 15px;
  font-weight: bold;
}
.approver-collapsible-container {
  display: grid;

  gap: 20px;
  padding: 5px;
  margin-top: 20px;

  /* grid-template-columns: [first] 40px [line2] 40px [line3] 40px [col4-start] 40px [five] 40px [end];
  grid-template-rows: [row1-start] 90px [row1-end] 90px [third-line] 90px [last-line]; */

  grid-template-columns: repeat(3, 1fr);
}

.add-group-input::placeholder {
  color: #9e9ea7;
}

.add-group-input {
  /* border: 2px solid transparent; */
  /* width: 15em; */
  width: 100%;
  height: 2.5em;
  /* padding-left: 0.8em; */
  outline: none;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.5s;
  border: 1px solid #9e9ea7;
}

.add-group-input:hover,
.add-group-input:focus {
  border: 1px solid #4a9dec;
  box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
  background-color: white;
}

