.vendor-tab {
  /* background-color: red;   */
  /* background-color: rgba(183, 175, 175, 0.295); */
  /* background-color: #637091; */
  border-radius: 10px;
  padding: 10px;
}

.vendor-tab-item {
  border: 1px solid red;
  background-color: blanchedalmond;
}

.vendor-tab-item:hover {
  /* transform: scale(1.07);
  transition: all 0.5s;
  background-color: white;
  color: #212121;
  border-radius: 20px; */
}
.customer-approval-container {
  display: flex;
  justify-content: space-between;
}
.customer-left-container {
  /* width: 20%; */
  display: flex;
  /* min-width: 100vw;
  max-width: 100vw; */
  overflow-x: auto;
}
.application-list-item {
  min-width: 173px;
  font-size: 18px;
  border: 1px solid #afafaf;
  padding: 10px;
  margin: 5px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}
.customer-right-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.customer-left-card {
  min-height: 100vh;
  padding: 10px;
}
.customer-right-card {
  min-width: 300px;
  justify-content: flex-start;
  display: flex;
}
.customer-right-card-body {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}
.customer-grid-container {
  /* display: grid; */
  /* .container { */
  display: grid;
  /* min-height: 50vh; */
  overflow-y: auto;
  /* max-height: 50vh; */

  /* grid-template-columns: [first] 40px [line2] 40px [line3] 40px [col4-start] 40px [five] 40px [end];
  grid-template-rows: [row1-start] 90px [row1-end] 90px [third-line] 90px [last-line]; */

  grid-template-columns: repeat(3, 1fr);
}
.customer-approval-card-container {
  min-height: 85vh;
}
.customer-approval-grid-container {
  /* display: grid; */
  /* .container { */
  display: grid;
  /* min-height: 50vh; */
  overflow-y: auto;
  /* max-height: 50vh; */
  gap: 10px;
  /* padding: 5px; */

  /* grid-template-columns: [first] 40px [line2] 40px [line3] 40px [col4-start] 40px [five] 40px [end];
  grid-template-rows: [row1-start] 90px [row1-end] 90px [third-line] 90px [last-line]; */

  grid-template-columns: repeat(3, 1fr);
}
.customer-no-edit-approval-grid-container {
  /* display: grid; */
  /* .container { */
  display: grid;
  /* min-height: 50vh; */
  overflow-y: auto;
  max-height: 50vh;
  min-height: 50vh;
  gap: 10px;
  padding: 5px;

  /* grid-template-columns: [first] 40px [line2] 40px [line3] 40px [col4-start] 40px [five] 40px [end];
  grid-template-rows: [row1-start] 90px [row1-end] 90px [third-line] 90px [last-line]; */

  grid-template-columns: repeat(2, 1fr);
}
.customer-grid-item {
  /* flex-direction: column; */
  max-width: 88%;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .customer-approval-grid-container {
    /* display: grid; */
    /* .container { */
    display: grid;
    min-height: 50vh;
    overflow-y: auto;
    max-height: 50vh;

    /* grid-template-columns: [first] 40px [line2] 40px [line3] 40px [col4-start] 40px [five] 40px [end];
  grid-template-rows: [row1-start] 90px [row1-end] 90px [third-line] 90px [last-line]; */

    grid-template-columns: repeat(1, 1fr);
  }
  .customer-grid-container {
  /* display: grid; */
  /* .container { */
  /* display: grid; */
  min-width: 100vw;
  /* min-height: 50vh; */
  /* overflow-y: auto; */
  /* max-height: 50vh; */

  /* grid-template-columns: [first] 40px [line2] 40px [line3] 40px [col4-start] 40px [five] 40px [end];
  grid-template-rows: [row1-start] 90px [row1-end] 90px [third-line] 90px [last-line]; */

  grid-template-columns: repeat(1, 1fr);
}
}
.otp-modal-container{
  min-width: 100%;
  min-height: 100vh;
/*   
    background: url("../../assets/IMAGES/loginCover.svg");
    background-repeat: no-repeat; */
    
}
.cookieCard {
  min-width: 400px;
  min-height: 250px;
  background: linear-gradient(to right,rgb(137, 104, 255),rgb(175, 152, 255));
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* gap: 20px; */
  /* box-shadow: 2px 2px 2px 2px #ccc;
   */
   box-shadow:
    inset 0 -3em 3em rgb(0 200 0 / 30%),
    /* 0 0 0 2px white, */
    0.3em 0.3em 1em rgba(36, 34, 34, 0.6);
  padding: 20px;
  top: 50%;
  left: 50%;

transform: translate(-50%,-50%);  position: absolute;
  overflow: hidden;
}

.cookieCard::before {
  width: 150px;
  height: 150px;
  content: "";
  background: linear-gradient(to right,rgb(142, 110, 255),rgb(208, 195, 255));
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
}

.cookieHeading {
  font-size: 1.5em;
  font-weight: 600;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription {
  font-size: 0.9em;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription a {
  color: rgb(241, 241, 241);
}

.acceptButton {
  padding: 11px 20px;
  background-color: #7b57ff;
  transition-duration: .2s;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  z-index: 2;
}

.acceptButton:hover {
  background-color: #714aff;
  transition-duration: .2s;
}

.excel-upload-modal-container{
  /* width: 600px;
  height: 400px; */
  background-color: #fff;
  padding: 10px;
}
.excel-upload-modal-child{
    width: 600px;
  height: 400px;

  max-height: 400px;
  overflow-y: auto;



}

.reset-button{
  position: relative;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #7d2ae8;
  transition: all 0.2s ease;
}
.reset-button:active {
  transform: scale(0.96);
}