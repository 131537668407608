.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.list-container {
  display: flex;
  font-size: 18px;
  background-color: #eee;
  flex-direction: column;
}
.item-container {
  background-color: #fff;
  border: 1px solid black;
  padding: 25px 70px;
  margin: 15px 50px;
}

body {
  background-color: #f0f0f0;
}
tr {
  border: 1px solid #ddd;
  color: black;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #fff;
  color: #000;
}
tr:nth-child(odd) {
  background-color: #fff;
  color: #000;
}

tr:hover {
  background-color: #ddd;
}

th {
  /* padding-top: 12px;
  padding-bottom: 12px; */
  text-align: center;
  background-color: #fff;
  color: #000;
  padding: 12px;
}
td {
  /* padding-top: 12px;
  padding-bottom: 12px; */
  text-align: center;
  /* background-color: #fff; */
  color: #000;
  padding: 12px;
}

/* ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-slider-thumb {
} */
/* ::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 50px;
} */
