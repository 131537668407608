.login-container {
  display: flex;
  justify-content: space-between;
}

.login-left-container {
  width: 30%;
  /* background-color: red; */
  /* min-height: 100vh; */
}
.login-right-container {
  width: 70%;
  /* background-color: green; */
  /* background-image: url("../../assets/IMAGES/loginBg.svg"); */
  background: url("../../assets/IMAGES/loginCover.svg") right bottom no-repeat,
    url("../../assets/IMAGES/loginBg.svg") left top repeat;
  /* padding: 15px; */

  min-height: 100vh;
}
.login-left-inner-container {
  margin-left: 50px;
  margin-right: 20px;
}
.form-container {
  margin-top: 50px;
}
.cover-img {
  /* z-index: 2;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  background-image: url("../../assets/IMAGES/loginCover.svg"); */
}

.header-text {
  font-size: 26px;
}
.sub-heading-text {
  font-size: 16px;
}
.input-box-title {
  font-size: 20px;
  font-family: poppins;
}
.inter-font {
  font-family: inter;
  color: #404040;
}
.signup-button {
  font-family: poppins;
  font-size: 16px;
  /* background: royalblue; */
  color: white;
  min-width: 175px;
  min-height: 48px;
  text-align: center;
  /* padding: 0.7em 1em; */
  /* padding-left: 0.9em; */
  display: flex;
  justify-content: "center";
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  background-image: linear-gradient(to right, #23b2d5, #219bcc);
  /* padding: 10px; */
}
.signup-button span {
  display: block;
  margin-left: 3em;
  transition: all 0.3s ease-in-out;
}

.signup-button:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

.forgot-password-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.forgot-password-text {
  color: #33acd1;
  cursor: pointer;
}

@media only screen and (max-width: 749px) {
  .login-container {
    display: flex;
    flex-direction: column;
  }
  .login-left-container {
    position: absolute;
    top: 10%;
    left: 5%;
    min-width: 90vw;
    background-color: #fff;
    min-height: 100px;
    padding-bottom: 10px;
    border-radius: 10px;
  }
  .login-right-container {
    min-width: 100vw;

    background: url("../../assets/IMAGES/loginCover.svg") right bottom no-repeat,
      url("../../assets/IMAGES/loginBg.svg") left top repeat;
  }
  .login-left-inner-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 480px) {
  .login-container {
    display: flex;
    flex-direction: column;
  }
  .login-left-container {
    position: absolute;
    top: 20%;
    left: 5%;
    min-width: 90vw;
    background-color: #fff;
    min-height: 100px;
    padding-bottom: 10px;
    border-radius: 10px;
  }
  .login-right-container {
    min-width: 100vw;

    background: url("../../assets/IMAGES/loginCover.svg") right bottom no-repeat,
      url("../../assets/IMAGES/loginBg.svg") left top repeat;
  }
  .login-left-inner-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}
