.main-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.Droppable {
  border: 1px solid #888;
  padding: 15px;
  background: #eee;
  height: 82vh;
  overflow-y: auto;

  overflow-y: auto;
  scroll-behavior: smooth;
}

.Draggable {
  border: 1px solid #888;
  padding: 15px;
  margin: 5px;
  background: #fafafa;
}
.droppable-item {
  /* background-color: red; */
  /* width: 100%; */
  padding: 5px;
}
.droppable-item:hover {
  /* cursor: grabbing; */
}
.sub-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}
.left-container {
  /* justify-content: flex-start; */

  width: 20%;
  /* height: 100vh; */
  overflow-y: auto;

  overflow-y: auto;
  scroll-behavior: smooth;

  /* background-color: lightblue; */
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  height: 96vh;
  /* padding: 10px; */
  margin: 1%;
}
.right-container {
  width: 78%;
  /* height: 93vh; */
  margin: 10px;
  overflow-y: auto;

  overflow-y: auto;
  scroll-behavior: smooth;
  /* overflow-y: auto; */
  /*  */
  /* overflow-y: auto; */
  /* scroll-behavior: smooth; */
  height: 96vh;
  background-image: url("../../assets/IMAGES/bg1.png");
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.grid-item {
  text-align: start;
  width: 100%;
  padding: 10px;
}

.save-button {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #40b3a2;
  min-width: 200px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 16px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}

.save-button:hover {
  opacity: 0.95;
  transform: scale(1.1);
  transition: all 0.5s;
}

.save-button .animation {
  border-radius: 100%;
  /* animation: ripple 0.6s linear infinite; */
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1),
      0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
      0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1),
      0 0 0 80px rgba(255, 255, 255, 0);
  }
}
.draggable-item-text {
  /* width: 300px; */
  /* text-wrap: wrap; */
  max-width: 200px;

  word-wrap: break-word;
}

.droppable-container {
  background-color: #fff;
  /* border: 1px solid #000; */
  /* border: 1px solid gray; */
  /* border-style: groove; */
  /* padding: 50px; */
  /* margin: 20px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.list-container {
  /* border: 1px dotted red; */
  background-color: white;
  min-height: 90vh;
  max-height: 90vh;
  min-width: 200px;
  /* max-width: 300px; */
  /* max-width: 250px; */
  overflow-y: auto;
  /* padding: 10px; */
  /* margin: 10px; */
  border-radius: 10px;

  /* margin-right: 10px;
  margin-left: 10px; */
}

.approver-main-container {
  display: flex;
  justify-content: space-between;
}
.approver-left-container {
  width: 30%;
}
.approver-right-container {
  width: 70%;
  margin-left: 20px;
}

.approver-left-card {
  min-height: 100vh;
  padding: 10px;
}
.approver-right-card {
  min-width: 300px;
  justify-content: flex-start;
  display: flex;
}
.approver-right-card-body {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}
.approver-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* background-color: red; */
}
.approver-grid-item {
  /* text-align: start; */
  flex-direction: column;
  /* height: 10vh; */
  /* width: 100%; */
  margin: 10px;
}
.icon-hover-delete:hover {
  /* background-color: #FF7F7F; */
  width: 25px;
  height: 25px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(20deg);
  transition: all 0.3s;
  cursor: pointer;
}

.blue-button {
  appearance: none;
  background-color: transparent;
  border: 0.125em solid #24bbd9;
  border-radius: 0.9375em;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  align-items: center;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  background-color: #24bbd9;
  margin: 0;
  min-height: 1.75em;
  min-width: 0;
  outline: none;
  /* padding: 0.6em 2.3em; */
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.blue-button:disabled {
  pointer-events: none;
}

.blue-button:hover {
  color: #fff;
  background-color: #24bbd9;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.blue-button:active {
  box-shadow: none;
  transform: translateY(0);
}

.blue-arrow-container {
  background-image: url("../../assets/IMAGES/left-arrow-blue.svg");
  /* padding: 20px; */
  width: 140px;
  height: 32px;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gray-arrow-container {
  background-image: url("../../assets/IMAGES/left-arrow-gray.svg");
  /* padding: 20px; */
  width: 100px;
  height: 32px;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-creation-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  padding: 5px;
  /* background-color: #fff; */
}
.input-admin-center {
  border: 2px solid transparent;
  width: 15em;
  height: 2.5em;
  padding-left: 0.8em;
  outline: none;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.5s;
}

.input-admin-center:hover,
.input-admin-center:focus {
  border: 2px solid #4a9dec;
  box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
  background-color: white;
}

.admin-control-center-container {
  background-color: #f0f0f0;
}

.sap-fields-list {
  display: flex;
  background-color: white;
  /* padding: 10px; */
  /* max-width: 100vw; */
  overflow-y: auto;
  max-height: 100vh;
}
