.back-ground-drawer {
  /* height: 100vh; */
  background-color: #001217;

  /* width: 282px; */
}
  .back-ground-drawer::-webkit-scrollbar {
  display: none;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}
.icon-image {
  filter: brightness(0) invert(1);
}

.drawer-item {
  color: #e4e4e4;
  padding: 20px;
  font-family: "poppins";
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.drawer-sub-item {
  color: #22aad2;
  padding: 20px;
  font-family: "poppins";
  background-color: #fff;

  font-size: 16px;
  display: flex;
  align-items: center;
  /* justify-content: ; */
  gap: 10px;
  margin-left: 20px;
}
.drawer-item:hover {
  /* transform: scale(1.03);
  transition: all 0.3s; */
  cursor: pointer;
}
.drawer-item-collapsible-container:hover {
  /* transform: scale(1.03);
  transition: all 0.3s; */
  cursor: pointer;
}
.drawer-item-collapsible-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-bar-item {
  color: #fff;
  /* background-color: red; */
}

.nav-bar-item:hover {
  transform: scale(1.1);
  transition: all 0.3s;
  background-color: #4c566f;
  color: #000;
}

.text-label {
  color: #fff;
}

aside {
  color: #fff;
  width: 250px;
  padding-left: 20px;
  /* height: 100vh; */
  /* background-image: linear-gradient(30deg, #0048bd, #44a7fd); */
  border-top-right-radius: 80px;
}

aside a {
  font-size: 12px;
  color: #fff;
  display: block;
  padding: 12px;
  padding-left: 30px;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

aside a:hover {
  color: #3f5efb;
  background: #fff;
  outline: none;
  position: relative;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

aside a i {
  margin-right: 5px;
}

aside a:hover::before {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 38px;
  right: 0;
  height: 35px;
  width: 35px;
  border-top-right-radius: 18px;
  box-shadow: 0 -20px 0 0 #fff;
}
aside a:hover::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 100%;
  right: 0;
  height: 35px;
  width: 35px;
  border-bottom-right-radius: 18px;
  box-shadow: 0 20px 0 0 #fff;
}

aside p {
  margin: 0;
  padding: 40px 0;
}

body {
  font-family: "Roboto";
  width: 100%;
  height: 100vh;
  margin: 0;
}

.social {
  height: 0;
}

.social i:before {
  width: 14px;
  height: 14px;
  font-size: 14px;
  position: fixed;
  color: #fff;
  background: #0077b5;
  padding: 10px;
  border-radius: 50%;
  top: 5px;
  right: 5px;
}
.drawer-sub-container {
  border-top-left-radius: 10px;
  border-radius: 50px;
  /* background-color: red; */
  /* margin-left: 20px; */
  /* display: flex; */
  /* justify-content: flex; */
  align-items: center;
}

.active {
  /* background-color: red; */

  /* box-shadow: 0 -20px 0 0 red;
  border-top-right-radius: 18px; */
}

/* .active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 38px;
  right: 0;
  height: 35px;
  width: 35px;
  border-top-right-radius: 18px;
  box-shadow: 0 -20px 0 0 #fff;
}
.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 100%;
  right: 0;
  height: 35px;
  width: 35px;
  border-bottom-right-radius: 18px;
  box-shadow: 0 20px 0 0 #fff;
} */
